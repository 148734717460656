/* You can add global styles to this file, and also import other style files */
@import '~vanilla-cookieconsent/dist/cookieconsent.css';

@font-face { font-family: 'PoppinsRegular'; font-display: swap; src: url('./assets/fonts/Poppins-Regular.ttf'); }
@font-face { font-family: 'PoppinsBold'; font-display: swap; src: url('./assets/fonts/Poppins-Bold.ttf'); }


$secondary-font-color: darkslategray;

ion-app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use 100% of the viewport height */
}

#cc-main {
  .pm__btn[data-role="all"], .cm__btn[data-role="all"] { background: green;}
}

//.pm__btn { background: pink !important}

ion-router-outlet {
  flex: 1; /* Allows the router outlet to expand and fill available space */
  display: block; /* Ensure it's block-level if not already */
}

ion-content { --overflow: auto; overflow: auto; }

ion-list.main-list { margin-bottom: 6vh; }

.default-zoom-color:hover { background: rgba(167, 199, 231, 1.0); }

.zoom-effect { transition: transform .2s; /* Animation */ }

.zoom-effect:hover {
  transform: scale(1.01); /* Scale the image up to 105% of its original size on hover */
  overflow: hidden;
}

.header-space {display: block; height: 6vh;}

//BREADCRUMBS
.breadcrumbs-container { height: auto; display: block;   padding-left: 10px;   padding-top: 10px;}

.breadcrumbs {
  cursor: pointer;
  small:hover { background: rgba(192, 212, 235, 0.8); }
  a { text-decoration: none; color: darkslategray; font-family: PoppinsRegular; }
}
//

.h1-container {
  min-height: 6vh;
  display: block;
  width: 100%;
}

.description {
  color: $secondary-font-color;
  font-style: italic;
  font-size: 1rem;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
  padding-top: 1.5vh;
  font-family: PoppinsRegular;
}

// searchbar list, related items, related categories
.item-image-container {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  height: auto;

  ion-skeleton-text {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  ion-img.article {
    padding-top: 24%;
  }

  ion-img {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
  }
}

.item-container {
  text-decoration: none;
  cursor: pointer;
}

a { text-decoration: none !important; }

// articles -list, home, news-list
.articles-list-image-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;

  ion-skeleton-text {
    width: 96%;
    aspect-ratio: 16/9;
    position: absolute;
    top: 2%;
    left: 2%;
  }
  ion-img {
    aspect-ratio: 16 / 9;
    width: 96%;
    height: auto;
    position: absolute;
    object-fit: cover !important;
    object-position: inherit;
    display: block;
    top: 2%;
    left: 2%;
  }
}

.category-name {
  font-size: 1.3rem;
  padding: 0.5rem 1rem 0 1rem;
  display: block;
  text-align: center;
  font-family: PoppinsBold;
  text-transform: uppercase;
  color: black;
  margin: 0 auto;
}

.item-extract {
  font-size: 0.9rem;
  text-align: center;
  color: black;
  width: 100%;
  display: block;
  font-family: PoppinsRegular;
}

.item-name {
  font-size: 1.1rem;
  padding: 0.3rem;
  display: block;
  text-align: center;
  font-family: PoppinsBold;
  color: darkslategray;
  margin: 0 auto;
}

.hidden { display: none; }

ion-img.fadeIn {
  position: relative;
  //animation: fadeInAnimation 0.1s ease-in-out;
  //animation-fill-mode: forwards;
}
// related items, related categories
.related-item-image-container {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
  display: inline-flex;
  height: auto;
  ion-skeleton-text { height: 100%; }
  ion-img.article {
    padding-top: 24%;
  }

  ion-img {
    position: absolute;
    aspect-ratio: 16/9;
    width: 100%;
  }
}

.related-item-name {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  text-align: center;
  font-family: PoppinsRegular;
  color: darkslategray;
  margin: 0 auto;
}

.related-items-heading, .related-categories-heading {
  margin: 1rem;
  font-family: PoppinsRegular;
  display: block;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  color: gray;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 0rem 1rem 3rem;
}


@keyframes fadeInAnimation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.ads { display: none; }

@media only screen  and (min-width : 600px) {
  ion-router-outlet { margin-top: 6vh; }

  .header-space {display: block; height: 8vh;}

  .ads { display: block }

  ion-list.main-list { margin-top: 4vh; }

  .category-name { padding: 1.5rem 1rem 0 1rem; }
}

@media only screen  and (min-width : 1200px) {

  .ads { display: block }

  .main-column { padding: 0 6rem}

  ion-list.main-list {
    margin-bottom: 0;
    margin-top: 5vh;
  }
}